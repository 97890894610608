<template>
  <div style='height: 100%; width: 100%'>
    <nesi-user-common :title='`预约评价`'>
      <!--      <template #button>
              <div class='btn-wrapper'>
                <el-button type='primary' plain @click='open'>添加预约评价</el-button>
              </div>
            </template>-->
      <template #query>
        <el-form :inline='true' :model='form' class='demo-form-inline'>

          <el-form-item label='房源坐落'>
            <el-input
              v-model='form.location'
              placeholder='请输入房源坐落'
              :clearable='true'
            />
          </el-form-item>
          <!-- <el-form-item label="核验状态">
            <el-select v-model="form.houseStauts" placeholder="核验状态">
              <el-option label="不通过" value="0" />
              <el-option label="核验中" value="1" />
              <el-option label="核验通过" value="2" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type='primary' @click='queryList'>查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #content>
        <el-table :data='tableData' style='width: 100%' v-loading='loading' size='small'>
          <el-table-column prop='location' label='坐落' width='180' />
          <el-table-column prop='grade' label='交易类型'>
            <template #default='{ row }'>
              <!--  1 预约看房(老数据没有的默认为预约看房)2 合同预约 3 二手房合同预约   -->
              <span v-if='row.orderType===1'>预约看房</span>
              <span v-else-if='row.orderType===2'>合同预约</span>
              <span v-else-if='row.orderType===3'>在线网签</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
          <el-table-column prop='name' label='评论人' align='center'>
            <template #default='{ row }'>
              <p>{{ row.name }}</p>
              <a
                style='color:#409eff;margin-right: 12px'
                @click='callPhone(row)'
              >拨打号码</a>
            </template>
          </el-table-column>
          <el-table-column prop='comment' label='评论内容' />
          <el-table-column prop='comment_tag' label='评价标签'>
            <template #default='{ row }'>
              <span v-if="row.comment_tag && row.comment_tag!='[]'">{{ JSON.parse(row.comment_tag).join(',') }}</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
          <el-table-column prop='comment_tag' label='反馈类型'>
            <template #default='{ row }'>
              <el-tag v-if='row.commentInfoType===1' size='small' type='primary'>评论</el-tag>
              <el-tag size='small' type='danger' v-else>投诉</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop='grade' label='评分' />
          <el-table-column prop='create_time' label='评论时间' />
          <el-table-column prop='publishName' label='操作' width='160'>
            <template #default='{ row }'>
              <a
                style='color:#409eff;margin-right: 12px'
                @click='open(row)'
              >回复
              </a>
              <a
                style='color:#409eff;margin-right: 12px'
                @click='openTableModal(row)'
              >历史回复
              </a>
              <!--              <a
                              style='color:#409eff;margin-right: 12px'
                              v-if="row.scans && row.scans !== '[]'"
                              @click='showImageDialog(row)'
                            >查看附件
                            </a>-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :page-size='pageSize'
          v-model:current-page='pageNo'
          background
          layout='prev, pager, next'
          :total='total'
          @current-change='currentChange'
        />
      </template>
    </nesi-user-common>
    <addModel @success='success' ref='model'></addModel>
    <CommentTableModel ref='commentTable'></CommentTableModel>
    <el-dialog
      v-model='imageDialogVisible'
      title='附件列表'
      width='75%'
    >
      <el-image
        style='width: 200px; height: 200px;margin-left: 10px;margin-top: 10px'
        v-for='(item,index) in imageList'
        :key='index'
        :src='item'
        :preview-src-list='imageList'
        fit='cover'
      />
    </el-dialog>

  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import addModel from './module/addModel.vue'
import CommentTableModel from './module/CommentTableModel.vue'

import { findOrderListAPI, getLandlordCommentList, remAppointment } from '@/api/appointComment.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, onMounted, defineComponent, computed, reactive, unref, h } from 'vue'
import { useStore } from 'vuex'
import { postFreeVirtualPhone } from '@/api/user-house'

const store = useStore()
defineComponent({
  NesiUserCommon
})
onMounted(() => {
  getAppointment()
})
// 当前角色
const identity = computed(() => {
  if (store.state.user.isTenant) {
    return 1
  } else {
    return 0
  }
})
const tableData = ref()
const pageNo = ref(1)
const pageSize = ref(10)
const total = ref()
const loading = ref(false)
const form = reactive({
  location: ''
})

// 分页
function currentChange() {
  getAppointment()
}

// 查询数据信息
const queryList = async () => {
  pageNo.value = 1
  await getAppointment()
}

// 获取表单
async function getAppointment() {
  const obj = {
    pageNo: pageNo.value,
    pageSize: pageSize.value,
    type: 1,
    ...form
  }
  try {
    loading.value = true
    const res = await getLandlordCommentList(obj)
    // console.log(res)
    tableData.value = res.records
    total.value = res.total
  } finally {
    loading.value = false
  }
}

// 添加成功事件
function success() {
  getAppointment()
}

const imageDialogVisible = ref(false)
const imageList = ref([])
const showImageDialog = (row) => {
  if (row.scans && row.scans !== '[]') {
    imageDialogVisible.value = true
    imageList.value = JSON.parse(row.scans).map(item => {
      return store.state.app.minioUrl + item.smjsj
    })
  }
}
const callPhone = async (row) => {
  const addResp = await postFreeVirtualPhone({ mode: 'ax', userId: row.user_id })
  ElMessageBox({
    title: '信息',
    message: h('p', null, [
      h('span', null, '请手机拨打联系人号码： ' + addResp)
    ]),
    showCancelButton: true,
    confirmButtonText: '确认',
    cancelButtonText: '关闭'
  }).then((action) => {

  })
}

// 添加表单模块
const model = ref(null)
const commentTable = ref(null)

function open(row) {
  model.value.openModel(row)
}

function openTableModal(row) {
  commentTable.value.openModel(row)
}
</script>

<style lang='scss' scoped>
.link {
  width: 70px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.required {
  color: #ff0000;
}

::v-deep .el-collapse-item__header {
  background: #f5f7fa;
  width: 100%;
  padding: 0 10px;
}

::v-deep .el-collapse-item__content {
  margin: 22px 0;
  padding: 0 10px;
}

::v-deep .el-table {
  font-size: 13px;
}

.w-80 {
  width: 80px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.indent-wrapper {
  p {
    margin-left: 20px;
  }
}

.w-p-48 {
  width: 48%;
}

::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
