import request from '@/utils/request'

/**
 * 【租客】查询我的预约服务列表
 */
export const findOrderListAPI = (data) => {
  return request({
    url: '/appoint/orderList',
    method: 'POST',
    data: data
  })
}

/**
 * 【房东】查询我的预约评价
 */
export const getLandlordCommentList = (data) => {
  return request({
    url: '/appoint/commentList',
    method: 'POST',
    data
  })
}

// 租客添加评论
export const saveCommentAPI = (data) => {
  return request({
    url: '/appoint/saveComment',
    method: 'POST',
    data
  })
}

// 回复评论
export const replyCommentAPI = (data) => {
  return request({
    url: '/appoint/replyComment',
    method: 'POST',
    data
  })
}

// 查看评论信息
export const getCommentDetailApi = (commentId) => {
  return request({
    url: '/appoint/commentDetail',
    method: 'GET',
    params: {
      commentId
    }
  })
}

/**
 * 查询预约评价列表
 */
export const findAppointment = (data) => {
  return request({
    url: '/publicSide/findAppointment',
    method: 'GET',
    params: data
  })
}
/**
 * 查询预约编号
 */
export const findAppointmentNumber = () => {
  return request({
    url: '/publicSide/findAppointmentNumber',
    method: 'GET'
  })
}
// 删除
export const remAppointment = (data) => {
  return request({
    url: '/publicSide/remAppointment',
    method: 'POST',
    params: data
  })
}
// 添加
export const addAppointment = (data) => {
  return request({
    url: '/publicSide/addAppointment',
    method: 'POST',
    data
  })
}
